<template>
    <div class="ui grid container-4">
      <div class="intro-content">
        <h1><span>Become an accredited</span> TayoCash Partner or Merchant <span>by following the steps below</span></h1>

        <div class="item">
          <p class="first"><span>Step 1. </span>Become a <span class="vtxt-align">Fully Verified Member</span> to apply as a TayoCash Partner or Merchant. Download the TayoCash account now.</p>
          
          <div class="qr">
            <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" target="_blank"><img class="qr-code" src="@/assets/qr-ios.png" alt="Tayocash">
              <span>iOS</span>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.tayocash" target="_blank"><img class="qr-code" src="@/assets/qr-android.png" alt="Tayocash">
              <span>Android</span>
            </a>
          </div>
        </div>

        <div class="item">
          <p><span>Step 2. </span> Once you are a Fully Verified Member, download and complete the
            <a class="link" href="https://assets.prod.tayocash.com/forms/partner_merchant_enrollment_form.pdf" download target="_blank"> TayoCash Partner & Merchant Enrollment Form</a> and submit via email to <a href="mailto:business@tayocash.com">business@tayocash.com</a>.</p>
        </div>

        <div class="item">
          <p><span>Step 3. </span>Wait for TayoCash to review your application and notify you once it is approved.</p>
        </div>

        <div class="item links">
          <a class="yellow" href="/faq/verification">HOW TO VERIFY ACCOUNT</a>
          <a class="white" href="/faq/services">HOW TO CASH IN</a>
        </div>
      </div>
      <div class="image-div">
        <img class="people" src="@/assets/pd4.svg">
      </div>
    </div>
</template>

<script>
export default {
  name: 'content4'
}
</script>

<style scoped lang='scss'></style>
